import Button from '@ui/Button';
import AuthProvider from 'context/AuthContext';
import { FacebookBrandIcon, GoogleBrandIcon, AppleBrandIcon } from './icons';

const SocialLoginButtons = ({
  handleSocialClick,
}: {
  handleSocialClick: (s: AuthProvider) => Promise<void>;
}) => (
  <div className="flex gap-[1.6rem]">
    <Button
      text={''}
      leadingIcon={<FacebookBrandIcon />}
      type="sso"
      onClick={() => handleSocialClick('facebook')}
      width="fluid"
    />
    <Button
      text={''}
      leadingIcon={<GoogleBrandIcon />}
      type="sso"
      onClick={() => handleSocialClick('google')}
      width="fluid"
    />
    <Button
      text={''}
      leadingIcon={<AppleBrandIcon />}
      type="sso"
      onClick={() => handleSocialClick('apple')}
      width="fluid"
    />
  </div>
);

export default SocialLoginButtons;
