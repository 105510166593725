import { CheckmarkIcon } from '..';
interface Props {
  checked?: boolean;
  onClick?: () => void;
  label?: string;
}
const CheckmarkIconButton = ({ checked, onClick, label }: Props) => {
  return (
    <div className="flex w-fit cursor-pointer items-center" onClick={onClick}>
      <button
        className={`flex h-[1.6rem] w-[1.6rem] items-center justify-center rounded-[.5rem] transition-colors focus:border-none focus:outline focus:outline-2 focus:outline-brand-secondary ${
          checked
            ? 'bg-brand-secondary'
            : 'border-[1px] border-brand-light-gray'
        }`}
        type="button"
      >
        {checked && <CheckmarkIcon height={8} width={12} variant="white" />}
      </button>
      {label && (
        <div className="ml-[1rem] select-none whitespace-wrap">{label}</div>
      )}
    </div>
  );
};

export default CheckmarkIconButton;
