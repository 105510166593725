interface LinkTargetBlankProps {
  href: string;
  children: React.ReactNode;
}

const LinkTargetBlank = ({ href, children }: LinkTargetBlankProps) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="font-medium text-brand-primary"
      href={href}
    >
      {children}
    </a>
  );
};

export default LinkTargetBlank;
