import { NextSeo } from "next-seo"; 
import { CheckmarkIcon } from '@c/icons';
import CheckmarkIconButton from '@c/icons/buttons/CheckmarkButton';
import SocialLoginButtons from '@c/SocialLoginButton';
import { Tab, Transition } from '@headlessui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@ui/Button';
import LinkTargetBlank from '@ui/Link/LinkTargetBlank';
import {
  createUserDocument,
  emailSchema,
  getUserById,
  getValidUserDocument,
  passwordSchema,
  updateUsername,
  usernameSchema,
} from '@util/firestore/users';
import { formatAuthError } from '@util/index';
import { cx } from 'class-variance-authority';
import AuthProvider, { useAuth } from 'context/AuthContext';
import { FirebaseError } from 'firebase/app';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import FormInput from './controls/FormInput';
import FormLabel from './controls/FormLabel';

export const ActiveCheckIcon = ({ active }: { active: boolean }) => {
  return (
    <div
      className={`flex h-[1.3rem] w-[1.3rem] items-center justify-center rounded-full p-[0.3rem] ${
        active ? 'bg-brand-primary' : 'bg-brand-gray'
      }`}
    >
      <CheckmarkIcon variant="white" height={12} width={12} />
    </div>
  );
};

const AuthForm = ({
  continueFn,
}: {
  continueFn: (uid: string, firstName: string, isSocial: boolean) => void;
}) => {
  const router = useRouter();
  const [authError, setAuthError] = useState<FirebaseError | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    loginSocial,
    createAccountEmailPassword,
    isLoggingIn,
    sendEmailVerification,
    fetchProviderForEmail,
  } = useAuth();

  const formSchema = z.object({
    email: emailSchema.superRefine(async (email, ctx) => {
      if (email?.includes('@') && email?.includes('.')) {
        const provider = await fetchProviderForEmail(email);
        if (provider)
          ctx.addIssue({
            code: 'custom',
            message: 'This email is already signed up with Gear Focus.',
          });
      }
    }),
    password: passwordSchema,
    consent: z
      .boolean()
      .refine((x) => !!x, 'must agree to ToS and Privacy Policy'),
    firstName: z
      .string()
      .min(2, 'First name must be at least 2 characters')
      .regex(/^[a-zA-Z ]+$/),
    lastName: z
      .string()
      .min(2, 'Last name must be at least 2 characters')
      .regex(/^[a-zA-Z ]+$/),
  });

  type Form = z.infer<typeof formSchema>;

  const { register, setValue, handleSubmit, formState, watch } = useForm<Form>({
    defaultValues: {
      password: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
  });

  const form = watch();
  const errors = formState.errors;

  const signup: SubmitHandler<Form> = async () => {
    setIsSubmitting(true);
    form.email = form.email.toLowerCase().trim();
    const createResp = await createAccountEmailPassword(form);
    if (createResp instanceof FirebaseError) {
      setIsSubmitting(false);
      setAuthError(createResp);
    } else if (
      createResp.user &&
      createResp.user.uid &&
      createResp.user.email
    ) {
      const docData = getValidUserDocument(
        createResp.user.uid,
        createResp.user.email,
        form
      );
      await Promise.allSettled([
        createUserDocument(docData, 'email'),
        sendEmailVerification(),
      ]);
      continueFn(createResp.user.uid, form.firstName, false);
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
      setAuthError(new FirebaseError('auth/unknown', 'unknown error'));
    }
  };

  const handleSocialClick = async (s: AuthProvider) => {
    setIsSubmitting(true);
    const res = await loginSocial(s);
    if (res instanceof FirebaseError) {
      setIsSubmitting(false);
      setAuthError(res);
    } else {
      if (res?.user?.uid) {
        const userDoc = await getUserById(res.user.uid);
        if (!userDoc) {
          const docData = getValidUserDocument(
            res.user.uid,
            res.user.email ?? '',
            {
              firstName: res.user.displayName
                ? res.user.displayName.split(' ')[0]
                : 'Anonymous',
              lastName: res.user.displayName?.split(' ').pop() ?? 'User',
            }
          );
          await createUserDocument(docData, s);
          continueFn(
            res.user.uid,
            res.user.displayName?.split(' ')?.[0] ?? '',
            true
          );
        } else router.push((router.query.redirect as string) ?? '/');
      } else {
        setIsSubmitting(false);
        setAuthError(new FirebaseError('auth/unknown', 'unknown error'));
      }
    }
  };

  return (
    <div className="w-full max-w-[36rem]">
      <h1 className="text-[2.4rem] font-semibold">Sign up to GearFocus</h1>
      <h2 className="mt-[0.4rem] text-tos">
        Create your account to like, purchase or chat with the seller.
      </h2>
      <div className="relative mt-[2.4rem] flex flex-col gap-[2rem] lg:mt-[3.2rem]">
        {authError && (
          <div className="absolute -top-10 w-full">
            <div className="m-auto w-fit justify-center rounded-[10px] border bg-red-200 px-[1.6rem] py-[0.8rem] font-medium text-red-900">
              {formatAuthError(authError)}
            </div>
          </div>
        )}
        <form
          className="flex flex-col gap-[2rem]"
          onSubmit={handleSubmit(signup)}
        >
          {/* email */}
          <FormLabel
            value="First Name"
            errorMessage={errors.firstName?.message}
          >
            <FormInput
              placeholder="First name"
              error={!!errors.firstName}
              {...register('firstName')}
              autoComplete="given-name"
            />
          </FormLabel>
          <FormLabel value="Last Name" errorMessage={errors.lastName?.message}>
            <FormInput
              placeholder="Last name"
              error={!!errors.lastName}
              {...register('lastName')}
              autoComplete="family-name"
            />
          </FormLabel>
          <FormLabel value="Email" errorMessage={errors?.email?.message}>
            <FormInput
              type="email"
              placeholder="Enter your email"
              error={!!errors.email}
              {...register('email')}
              autoComplete="email"
            />
          </FormLabel>

          {/* password */}
          <FormLabel value="Password">
            <FormInput
              type="password"
              placeholder="Enter your password"
              error={!!errors.password}
              {...register('password')}
              autoComplete="new-password"
            />
            <div className="mt-[0.8rem] flex flex-col gap-[0.8rem] text-[1.3rem]">
              <div className="flex items-center gap-[0.6rem]">
                <ActiveCheckIcon active={form.password.length >= 8} />
                <p>8 or more characters</p>
              </div>
              <div className="flex items-center gap-[0.6rem]">
                <ActiveCheckIcon
                  active={
                    !!form.password.match(/[a-z]/) &&
                    !!form.password.match(/[A-Z]/)
                  }
                />
                <p>Upper & lowercase letters</p>
              </div>
              <div className="flex items-center gap-[0.6rem]">
                <ActiveCheckIcon active={!!form.password.match(/[0-9]/)} />
                <p>At least one number</p>
              </div>
            </div>
          </FormLabel>

          {/* agree to TOS & Privacy Policy */}
          <div
            className={cx([
              'flex h-[2.4rem] items-center gap-[0.8rem]',
              !!errors.consent ? 'rounded-sm border-2 border-red-500' : '',
            ])}
          >
            <CheckmarkIconButton
              onClick={() => setValue('consent', !form.consent)}
              checked={form.consent}
            />
            <p className="text-tos">
              I agree to&nbsp;
              <LinkTargetBlank href="/terms-and-conditions">
                Terms of Service
              </LinkTargetBlank>
              &nbsp;and&nbsp;
              <LinkTargetBlank href="/privacy-policy">
                Privacy Policy
              </LinkTargetBlank>
              .
            </p>
          </div>
          {/* login button */}
          <Button
            text={'Sign up'}
            type="secondary"
            //disabled={!form.firstName || !form.lastName || !form.email || !form.password || !form.consent}
            loading={isLoggingIn || isSubmitting}
            buttonType="submit"
          />
        </form>
        {/* divider */}
        <div className="flex h-[2.4rem] justify-between">
          <div className="mx-[1rem] h-1/2 grow border-b-[1px] border-brand-light-gray" />
          <p>or continue with</p>
          <div className="mx-[1rem] h-1/2 grow border-b-[1px] border-brand-light-gray" />
        </div>
        <SocialLoginButtons handleSocialClick={handleSocialClick} />
      </div>
      <div className="my-[4.8rem] flex w-full justify-center lg:my-[6rem]">
        <p>
          Already have an account?&nbsp;
          <Link href="/login" className="font-semibold text-brand-secondary">
            Log in
          </Link>
        </p>
      </div>
    </div>
  );
};

const UsernameForm = ({
  uid,
  firstName,
  isSocial,
}: {
  uid: string;
  firstName: string;
  isSocial: boolean;
}) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, handleSubmit, formState } = useForm<{
    username: string;
  }>({
    defaultValues: {
      username: firstName + "'s Gear Shop",
    },
    mode: 'onBlur',
    resolver: zodResolver(z.object({ username: usernameSchema })),
  });
  const errors = formState.errors;

  const submit: SubmitHandler<{ username: string }> = async (data) => {
    setIsSubmitting(true);
    await updateUsername(uid, data.username);
    await queryClient.invalidateQueries(['user']);
    if (isSocial) router.push('/dashboard/edit?conversion=signup');
    else router.push('/verify-email?conversion=signup');
    setIsSubmitting(false);
  };

  return (
    <div className="w-full max-w-[36rem]">
      <h1 className="text-[2.4rem] font-semibold">Create a Username</h1>
      <h2 className="mt-[0.4rem] text-tos">
        This will be displayed publicly to other users.
      </h2>
      <div className="relative mt-[2.4rem] flex flex-col gap-[2rem] lg:mt-[3.2rem]">
        <form
          className="flex flex-col gap-[2rem]"
          onSubmit={handleSubmit(submit)}
        >
          <FormLabel
            required
            value="Username"
            errorMessage={errors.username?.message}
          >
            <FormInput
              placeholder="Username"
              error={!!errors.username}
              autoComplete="off"
              {...register('username')}
            />
          </FormLabel>
          <div className="mt-5 w-full">
            <Button
              text="Submit"
              type="secondary"
              loading={isSubmitting}
              buttonType="submit"
              width="fluid"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

const SignupForm = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [userInfo, setUserInfo] = useState({
    uid: '',
    firstName: '',
    isSocial: false,
  });

  return (
    <>
      <NextSeo
        title="Sign Up for GearFocus - Buy and Sell Used Camera Gear"
        description="Join GearFocus to buy and sell used camera gear easily. Create your account today and access a marketplace designed for photographers and creators."
        openGraph={{
          images: [
            {
              url: "https://www.gearfocus.com/gear_pile.png",
              width: 800,
              height: 600,
              alt: "Sign Up for GearFocus - Buy and Sell Used Camera Gear",
              type: "image/jpeg",
            },
          ],
          siteName: "GearFocus Marketplace",
          type: 'website',
          url: "https://www.gearfocus.com/signun",
        }}
      />
      <div 
        className="
          mt-[2.4rem] flex w-full items-center 
          justify-center px-[2.4rem] pb-[3.2rem] 
          sm:p-0 lg:mt-[3rem]
        "
      >
        {/* form */}

        {/* inputs and buttons */}
        <Tab.Group selectedIndex={tabIndex} onChange={setTabIndex}>
          <Tab.List className="hidden">
            <Tab></Tab>
            <Tab></Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <Transition
                appear
                show={tabIndex == 0}
                enter="transition-opacity duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <AuthForm
                  continueFn={(uid, firstName, isSocial) => {
                    setUserInfo({ uid, firstName, isSocial });
                    setTabIndex(1);
                  }}
                />
              </Transition>
            </Tab.Panel>
            <Tab.Panel>
              <Transition
                appear
                show={tabIndex == 1}
                enter="transition-opacity duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <UsernameForm {...userInfo} />
              </Transition>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
};

export default SignupForm;
