import { EyeSlashIcon } from '@c/icons';
import { InputStyles } from '@ui/Input';
import React, { useState } from 'react';

interface InputProps {
  placeholder?: string;
  error?: boolean;
  type?: 'password' | 'email' | 'text' | 'number' | 'date' | 'tel';
  disabled?: boolean;
  readonly?: boolean;
  center?: boolean;
  defaultValue?: string | number;
  mask?: string;
  onDark?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: string | number;
  autoComplete?: string;
  unit?: 'lb' | 'in' | 'oz';
}

const FormInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      placeholder,
      error,
      type = 'text',
      center,
      defaultValue,
      unit,
      onDark,
      readonly,
      disabled,
      ...rest
    },
    ref
  ) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const getType = () => {
      if (type === 'password') {
        if (passwordVisible) return 'text';
        return 'password';
      }
      if (type === 'date') {
        return 'date';
      }
      if (type === 'number') {
        return 'number';
      }
      if (type === 'tel') {
        return 'tel';
      }
      return 'text';
    };
    return (
      <div
        className={InputStyles({
          type,
          onDark,
          full: true,
          error,
          focus: 'default',
          readonly,
          disabled,
        })}
      >
        <input
          placeholder={placeholder}
          className={`w-full border-none border-transparent bg-transparent p-0 text-[1.6rem] leading-input text-inherit placeholder-gray-300 focus:outline-none focus:ring-0 sm:text-input ${
            center ? 'text-center' : ''
          }`}
          type={getType()}
          ref={ref}
          defaultValue={defaultValue}
          disabled={!!disabled}
          step={type === 'number' ? 'any' : undefined}
          {...rest}
        />
        {type === 'password' && (
          <div
            className="cursor-pointer text-brand-gray"
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            <EyeSlashIcon />
          </div>
        )}
        {!!unit && <div>{unit}</div>}
      </div>
    );
  }
);
FormInput.displayName = 'FormInput';
export default FormInput;
